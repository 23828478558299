import React, { useState } from "react";
import Button from "components/FormElements/Button";

import IconTextBox from "components/FormElements/IconTextBox";
import { generatePassword } from "helpers/helpersFunc";
import { forgotPasswordCheckCode } from "services/allServices";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import moment from "moment";
import Validation from "../Validation";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "next-i18next";

function NewPasswordModal({ onClick, mail, closeModal }) {
  const [otp, setOtp] = useState("");
  const [pass, setPass] = useState("");
  const [passAgain, setPassAgain] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState();
  const [showDanger, setShowDanger] = useState(false);
  const { t } = useTranslation(["common", "nav", "signup"]);

  const handleChange = otp => setOtp(otp);

  const onGeneratePassword = () => {
    let genPass = generatePassword();
    setGeneratedPassword(genPass);
    setPass(genPass);
    setPassAgain(genPass);
  };

  const copyToClipboard = (e) => {
    if (generatedPassword) {
      alert("Şifrenizi Panoya Kopyaladınız.");
    } else {
      alert("Önce bir şifre giriniz.");
    }

  };

  const onChangePassword = () => {
    if (otp !== "" && pass !== "" && passAgain !== "") {
      forgotPasswordCheckCode(mail, otp, pass, passAgain).then(res => {
        if (res.status === "password_changed") {
          onClick();
        } else {
          if (res.message === "code_already_used") {
            setShowDanger("Kod daha önceden kullanıldı.");
          }else if (res.status === "code_expire") {
            setShowDanger("Doğrulama kodunun süresi dolmuş. Lütfen tekrar kod alınız.");
          } else if (res.message === "not_match") {
            setShowDanger("Doğrulama kodu eşleşmiyor.");
          } else if (res.message === "passwords_does_not_match") {
            setShowDanger("Parola eşleşmiyor.");
          } else if (res.message === "password_lenght_not_enough") {
            setShowDanger("Parola yeterli uzunlukta değil.");
          } else if (res.message === "password_not_valid") {
            setShowDanger(t("passwordIncompatibleError") + (res?.available ? (" ") + t("passwordCharacterError") + " " + res?.available?.join(" ") : ""));
          } else if (res.message === "you_are_banned") {
            setShowDanger(`Hesabınız ${moment(res.ban_end_time).format("DD/MM/YY HH:mm")} tarihine kadar banlanmıştır.`);
          } else if (res.message === "account_blocked") {
            setShowDanger(`Hesabınız ${moment(res.ban_end_time).format("DD/MM/YY HH:mm")} tarihine kadar banlanmıştır.`);
          } else {
            setShowDanger("Bir hata oluştu lütfen tekrar deneyiniz. Çözülmezse destek ekibimizle iletişime geçiniz.");
          }


        }
      });
    } else {
      setShowDanger("Lütfen boş alan bırakmayınız.");
    }

  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
        <div
          className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="text-center ">
                <div className="flex items-center justify-between px-4 py-6 border-b-2 border-makdosSecondaryGray">
                  <div>

                  </div>
                  <h3 className="text-xl font-medium leading-6 text-selectboxText" id="modal-headline">
                    Yeni Parola
                  </h3>
                  <div>
                    <i className="text-xl cursor-pointer ri-close-line text-selectboxText" onClick={() => closeModal()}/>
                  </div>
                </div>
                <div className="px-10 mt-8">
                  {showDanger && (
                    <div className="mb-4 text-left">
                      <AlertDanger message={showDanger} />
                    </div>
                  )}
                  <p className="text-lg">Doğrulama Kodu</p>
                  <div className="flex justify-center my-4">

                    <Validation
                      value={otp}
                      onChange={(v)=>setOtp(v.toLocaleUpperCase("tr"))}
                      autoFocus
                      inputClassName={
                        "text-gray-500 border border-textGray  focus:border-1 focus:border-makdosYellow focus:ring-1 focus:ring-makdosYellow focus:outline-none"
                      }
                      OTPLength={6}
                      otpType="string"
                      disabled={false}
                      secure
                    />
                  </div>

                  <p className="text-center text-md text-cardText">
                    Yeni parolanızı minimum 8 karakter, büyük harf, küçük harf, sayı ve özel karakter içerecek şekilde
                    oluşturabilirsiniz.
                  </p>
                  <div className="mt-4 text-left">
                    <IconTextBox value={pass} onChange={(v) => setPass(v)} labelName="Parola" inputName="password"
                                 type="password" placeholder="******" iconChange={true} textBoxIcon="ri-eye-line"
                                 textBoxSecondIcon="ri-eye-off-line" />

                  </div>
                  <div className="mt-4 text-left">
                    <IconTextBox value={passAgain} onChange={(v) => setPassAgain(v)} labelName="Parola Yeniden"
                                 inputName="passwordAgain" type="password" placeholder="12345678" iconChange={true}
                                 textBoxIcon="ri-eye-line" textBoxSecondIcon="ri-eye-off-line" />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between px-10 mt-4">
            <div onClick={() => onGeneratePassword()} className="mr-5 cursor-pointer">Parola Oluştur</div>
            <CopyToClipboard text={generatedPassword} onCopy={() => copyToClipboard()}>
              <div className="cursor-pointer">Kopyala</div>
            </CopyToClipboard>
          </div>
          <div className="w-full px-10 py-3 mb-5 lg:flex">
            <div className="w-full mt-5 lg:mt-4">
              <Button onClick={() => onChangePassword()} name="go" buttonText="Parolamı Değiştir" />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default NewPasswordModal;
