import React from "react";

const HeaderCard = React.forwardRef(({ icon, title, description, href,as, onClick, disable }, ref) => {
  return (
    <div ref={ref} onClick={onClick}
       className={` col-span-1 rounded-md border-2 mt-2 lg:mt-0 py-5 px-4 border-makdosSecondaryGray ${disable ? "opacity-40 cursor-not-allowed" : "hover:bg-makdosYellow"}   transition-all  duration-300 hover:shadow-yellowShadow hover:border-transparent cursor-pointer`}>
      <div className="flex">
        <div className="px-1 mr-2">
          <i className={icon + " text-3xl"}></i>
        </div>
        <div className="w-full">
          <h3 className="text-lg font-semibold text-selectboxText">{title}</h3>
          <p className="text-sm text-cardText">{description}</p>
        </div>
      </div>

    </div>
  );
});

export default HeaderCard;
