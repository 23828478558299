import React from "react";

function SuccessModal({ setOpenModal, title, message, buttonText, onClick }) {

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ overflow: "initial" }}
        >
          <div className="bg-white rounded-md">
            <div className="flex justify-end items-center px-3 py-3">
              <i
                onClick={() => setOpenModal(false)}
                className="ri-close-line text-2xl text-passive cursor-pointer"
              ></i>
            </div>
            <div className="w-full flex flex-col justify-center items-center px-5 pt-4 pb-10 ">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2"
                      d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z"
                      fill="#45D85B" />
                <path d="M53.75 32.5L35.4166 50L26.25 41.25" stroke="#45D85B" stroke-width="6" stroke-linecap="round"
                      stroke-linejoin="round" />
                <path
                  d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70Z"
                  stroke="#45D85B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <p className="mt-5 text-alertGreen text-2xl">{title}</p>
              <p className="mt-5 text-center text-cardText">{message}</p>
              <div className="w-full mt-10 px-20">
                <button type="button" onClick={() => {
                  onClick && onClick();
                }}
                        className="w-full py-4 px-4  flex items-center justify-center text-textWhite rounded-md font-medium bg-alertGreen hover:bg-makdosLaci hover:text-textWhite transition-colors duration-300 tr text-md focus:outline-none">
                  {buttonText}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
