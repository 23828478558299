import create from "zustand";
import { devtools } from "zustand/middleware";
import * as ls from "local-storage";

export const useConfigurationStore = create(devtools((set, get) => ({

  configurationTotal: [],
  configuration: [],
  componentValues: [],
  addComponentItem: (id, CompName, price, count, componentId, selectBoxName, configurationName, compOrder, raid, displayName, details) => {
    if (get().componentValues.length === 0) {
      set((state) => ({
        componentValues: [
          ...get().componentValues,
          {
            id: id,
            CompName: CompName,
            price: price,
            count: count,
            componentId: componentId,
            selectBoxName: selectBoxName,
            configurationName: configurationName,
            compOrder: compOrder,
            raid: raid,
            displayName: displayName,
            details: details
          }
        ]
      }));
      get().componentValues.sort((a, b) => a.compOrder - b.compOrder);
    } else {
      let isExist = false;
      get().componentValues.map((c, i) => {
        if ((c.CompName === CompName) && (c.selectBoxName === selectBoxName)) isExist = true;
        if (i + 1 === get().componentValues.length && isExist === false) {
          set((state) => ({
            componentValues: [
              ...get().componentValues,
              {
                id: id,
                CompName: CompName,
                price: price,
                count: count,
                componentId: componentId,
                selectBoxName: selectBoxName,
                configurationName: configurationName,
                compOrder: compOrder,
                raid: raid,
                displayName: displayName,
                details: details
              }
            ]
          }));
          get().componentValues.sort((a, b) => a.compOrder - b.compOrder);
        }
      });
    }
    get().addToLocalStorage();
  },
  addComponent: (id, name, group, productId, productName) => {
    if (get().configuration.length === 0) {
      set((state) => ({
        configuration: [
          ...get().configuration,
          {
            id: id,
            name: name,
            group: group,
            productId: productId,
            productName: productName
          }
        ]
      }));
    } else {
      let isExist = false;
      get().configuration.map((c, i) => {
        if (c.id === id) isExist = true;
        if (i + 1 === get().configuration.length && isExist === false) {
          set((state) => ({
            configuration: [
              ...get().configuration,
              {
                id: id,
                name: name,
                group: group,
                productId: productId,
                productName: productName
              }
            ]
          }));
        }
      });
    }
    get().addToLocalStorage();
  },
  addProduct: (id, name) => {
    if (get().configurationTotal.length === 0) {
      set((state) => ({
        configurationTotal: [
          ...get().configurationTotal,
          {
            id: id,
            name: name

          }
        ]
      }));
    } else {
      let isExist = false;
      get().configurationTotal.map((c, i) => {
        if (c.id === id) isExist = true;
        if (i + 1 === get().configurationTotal.length && isExist === false) {
          set((state) => ({
            configurationTotal: [
              ...get().configurationTotal,
              {
                id: id,
                name: name
              }
            ]
          }));
        }
      });
    }
    get().addToLocalStorage();
  },
  removeComponentItemWithSelectBoxName: (selectBoxName, compId) => {
    const newList = [];
    get().componentValues.map((item) => {
      if (item.selectBoxName !== selectBoxName) {
        newList.push(item);
      }
    });
    set((state) => ({
      componentValues: newList
    }));
    get().addToLocalStorage();


  },
  clearComponentValues: () => {
    set((state) => ({
      componentValues: []
    }));
    get().addToLocalStorage();
  },
  removeComponentItem: (name) => {
    set((state) => ({
      componentValues: get().componentValues.filter((c) => c.CompName !== name)
    }));
    get().addToLocalStorage();
  },
  removeComponent: (id) => {
    set((state) => ({
      configuration: get().configuration.filter((c) => c.id !== id)
    }));
    get().componentValues.map((x) => {

      if (x.componentId === id) get().removeComponentItemWithSelectBoxName(x.selectBoxName);
    });
    get().addToLocalStorage();

  },
  removeProduct: (id) => {
    set((state) => ({
      configurationTotal: get().configurationTotal.filter((c) => c.id !== id)
    }));
    get().configuration.map((x) => {
      if (x.productId === id) get().removeComponent(x.id);
    });
    get().addToLocalStorage();
  },


  selectedDisk1: [],
  addSelectedDisk1: (id, CompName, price, count, componentId, selectBoxName, configurationName, compOrder, raid, diskSize, displayName, details) => {
    set((state) => ({
      selectedDisk1: [
        {
          id: id,
          CompName: CompName,
          price: price,
          count: count,
          componentId: componentId,
          selectBoxName: selectBoxName,
          configurationName: configurationName,
          compOrder: compOrder,
          raid: raid,
          diskSize: diskSize,
          displayName: displayName,
          details: details
        }
      ]
    }));
    get().addToLocalStorage();
  },
  deleteSelectedDisk1: () => {
    set((state) => ({
      selectedDisk1: []
    }));
    get().addToLocalStorage();
  },
  selectedDisk2: [],
  addSelectedDisk2: (id, CompName, price, count, componentId, selectBoxName, configurationName, compOrder, raid, diskSize, details) => {
    set((state) => ({
      selectedDisk2: [
        {
          id: id,
          CompName: CompName,
          price: price,
          count: count,
          componentId: componentId,
          selectBoxName: selectBoxName,
          configurationName: configurationName,
          compOrder: compOrder,
          raid: raid,
          diskSize: diskSize,
          details: details
        }
      ]
    }));
    get().addToLocalStorage();
  },

  deleteSelectedDisk2: () => {
    set((state) => ({
      selectedDisk2: []
    }));
    get().addToLocalStorage();
  },

  selectedOS: null,
  setSelectedOS: (item) => {
    set((state) => ({
      selectedOS: item
    }));
    get().addToLocalStorage();
  },


  selectedSubOS: null,
  setSelectedSubOS: (item) => {
    set((state) => ({
      selectedSubOS: item
    }));
    get().addToLocalStorage();
  },


  backupStack: [
    {
      raid_level: null,
      disk_size: null,
      number_of_disk: null
    },
    {
      raid_level: null,
      disk_size: null,
      number_of_disk: null
    },
    {
      raid_level: null,
      disk_size: null,
      number_of_disk: null
    }],
  addBackupStack: (selectBoxId, backupRequired) => {
    if (selectBoxId === "1") {
      let tmp = [];
      tmp.push(backupRequired);
      tmp.push(get().backupStack[1]);
      tmp.push(get().backupStack[2]);
      set((state) => ({
        backupStack:
        tmp

      }));
    } else if (selectBoxId === "2") {
      let tmp = [];
      tmp.push(get().backupStack[0]);
      tmp.push(backupRequired);
      tmp.push(get().backupStack[2]);
      set((state) => ({
        backupStack:
        tmp

      }));
    } else {
      let tmp = [];
      tmp.push(get().backupStack[0]);
      tmp.push(get().backupStack[1]);
      tmp.push(backupRequired);
      set((state) => ({
        backupStack:
        tmp

      }));
    }
    get().addToLocalStorage();
  },

  removeBackupStackWithSelectBoxId: (selectBoxId) => {
    if (selectBoxId === "1") {
      let tmp = [{
        raid_level: null,
        disk_size: null,
        number_of_disk: null
      }];
      tmp.push(get().backupStack[1]);
      tmp.push(get().backupStack[2]);
      set((state) => ({
        backupStack:
        tmp

      }));
    } else if (selectBoxId === "2") {
      let tmp = [];
      tmp.push(get().backupStack[0]);
      tmp.push({
        raid_level: null,
        disk_size: null,
        number_of_disk: null
      });
      tmp.push(get().backupStack[2]);
      set((state) => ({
        backupStack:
        tmp

      }));
    }
    get().addToLocalStorage();

  },
  backupPrice: 0,
  setBackupPrice: (price) => {
    set((state) => ({
      backupPrice:
      price

    }));
    get().addToLocalStorage();

  },

  wafPrice: 0,
  setWafPrice: (price) => {
    set((state) => ({
      wafPrice:
      price

    }));
    get().addToLocalStorage();

  },


  IPSPrice: 0,
  setIPSPrice: (price) => {
    set((state) => ({
      IPSPrice:
      price

    }));
    get().addToLocalStorage();

  },

  DDOSPrice: [],
  setDDOSPrice: (price) => {
    set((state) => ({
      DDOSPrice:
      price

    }));
    get().addToLocalStorage();

  },

  SelectedDDOSPrice: [],
  setSelectedDDOSPrice: (price) => {
    set((state) => ({
      SelectedDDOSPrice:
      price

    }));
    get().addToLocalStorage();

  },
  OSPrice: [],
  setOSPrice: (price) => {
    set((state) => ({
      OSPrice:
      price

    }));
    get().addToLocalStorage();

  },
  DBPrice: [],
  setDBPrice: (price) => {
    set((state) => ({
      DBPrice:
      price

    }));
    get().addToLocalStorage();

  },
  totalCpuCore: ls.get("__totalCpuCore") || 0,
  setTotalCpuCore: (cpuCore) => {
    set((state) => ({
      totalCpuCore:
      cpuCore
    }));
    get().addToLocalStorage();
  },

  selectedCpu: ls.get("__selectedCpu") || null,
  setSelectedCpu: (cpu) => {
    set((state) => ({
      selectedCpu:
      cpu
    }));
    get().addToLocalStorage();
  },

  selectedFirewall: ls.get("__selectedFirewall") || null,
  setSelectedFirewall: (fw) => {
    set((state) => ({
      selectedFirewall:
      fw
    }));
    get().addToLocalStorage();
  },

  selectedServer: ls.get("__selectedServer") || null,
  setSelectedServer: (id) => {
    set((state) => ({
      selectedServer: id,
      configurationTotal: [],
      configuration: [],
      componentValues: []
    }));
    get().addToLocalStorage();
  },

  defaultPaymentCycle: "one_month",
  setDefaultPaymentCycle: (price) => {
    if (price === 1) {
      set(() => ({
        defaultPaymentCycle: "one_month"
      }));
    } else if (price === 3) {
      set(() => ({
        defaultPaymentCycle: "three_month"
      }));
    } else if (price === 6) {
      set(() => ({
        defaultPaymentCycle: "six_month"
      }));
    } else if (price === 12) {
      set(() => ({
        defaultPaymentCycle: "one_year"
      }));
    } else if (price === 24) {
      set(() => ({
        defaultPaymentCycle: "two_year"
      }));
    } else if (price === 36) {
      set(() => ({
        defaultPaymentCycle: "three_year"
      }));
    }
    localStorage.setItem("__defaultPaymentCycle", JSON.stringify(get().defaultPaymentCycle));
  },

  basketCount: 0,
  setBasketCount: (count) => {
    set((state) => ({
      basketCount:
      count
    }));
    get().addToLocalStorage();
  },

  basketTimeChange: false,
  setBasketTimeChange: (value) => {
    set((state) => ({
      basketTimeChange:
      value
    }));
  },
  controlPanelDetails: {},
  setControlPanelDetails: (details) => {
    set((state) => ({
      controlPanelDetails:
      details
    }));
    get().addToLocalStorage();
  },

  configurationNote: "",
  setConfigurationNote: (value) => {
    set((state) => ({
      configurationNote:
      value
    }));
    get().addToLocalStorage();

  },

  coLocationBackupPrice: ls.get("__configurationType") ? ls.get("__configurationType") :  "no-select",
  setConfigurationType: (type) => {
    set((state) => ({
      configurationType: type

    }));
    get().addToLocalStorage();
  },

  coLocationBackupPrice: 0,
  setCoLocationBackupPrice: (price) => {
    set((state) => ({
      coLocationBackupPrice:
      price

    }));
    get().addToLocalStorage();

  },

  selectedYear: {},
  setSelectedYear: (year) => {
    set((state) => ({
      selectedYear: year
    }));
    get().addToLocalStorage();

  },


  clearAllConfigurations: () => {
    set((state) => ({
      configurationTotal: [],
      configuration: [],
      componentValues: [],
      selectedDisk1: [],
      selectedDisk2: [],
      selectedOS: null,
      selectedSubOS: null,
      wafPrice: 0,
      ıpsPrice: 0,
      SelectedDDOSPrice: [],
      selectedCpu: null,
      selectedFirewall: null,
      configurationNote: "",
      backupStack: [
        {
          raid_level: null,
          disk_size: null,
          number_of_disk: null
        },
        {
          raid_level: null,
          disk_size: null,
          number_of_disk: null
        },
        {
          raid_level: null,
          disk_size: null,
          number_of_disk: null
        }],
      backupPrice: 0,
      coLocationBackupPrice: 0,
      selectedYear: 0,
      defaultPaymentCycle: "one_month",
      controlPanelDetails: {}

    }));

    get().setWafPrice(0);
    get().setIPSPrice(0);
    get().setDDOSPrice([]);
    get().addToLocalStorage();
  },


  addToLocalStorage: () => {
    localStorage.removeItem("__products");
    localStorage.removeItem("__configuration");
    localStorage.removeItem("__componentValues");
    localStorage.removeItem("__selectedDisk1");
    localStorage.removeItem("__selectedDisk2");
    localStorage.removeItem("backupStack");

    localStorage.setItem("__products", JSON.stringify(get().configurationTotal));
    localStorage.setItem("__configuration", JSON.stringify(get().configuration));
    localStorage.setItem("__componentValues", JSON.stringify(get().componentValues));
    localStorage.setItem("__selectedDisk1", JSON.stringify(get().selectedDisk1));
    localStorage.setItem("__selectedDisk2", JSON.stringify(get().selectedDisk2));
    localStorage.setItem("__backupStack", JSON.stringify(get().backupStack));
    localStorage.setItem("__wafPrice", JSON.stringify(get().wafPrice));
    localStorage.setItem("__IPSPrice", JSON.stringify(get().IPSPrice));
    localStorage.setItem("__DDOSPrice", JSON.stringify(get().DDOSPrice));
    localStorage.setItem("__SelectedDDOSPrice", JSON.stringify(get().SelectedDDOSPrice));
    localStorage.setItem("__OSPrice", JSON.stringify(get().OSPrice));
    localStorage.setItem("__DBPrice", JSON.stringify(get().DBPrice));
    localStorage.setItem("__totalCpuCore", JSON.stringify(get().totalCpuCore));
    localStorage.setItem("__selectedCpu", JSON.stringify(get().selectedCpu));
    localStorage.setItem("__selectedFirewall", JSON.stringify(get().selectedFirewall));

    localStorage.setItem("__selectedOS", JSON.stringify(get().selectedOS));
    localStorage.setItem("__selectedSubOS", JSON.stringify(get().selectedSubOS));

    localStorage.setItem("__selectedServer", JSON.stringify(get().selectedServer));
    localStorage.setItem("__basketCount", JSON.stringify(get().basketCount));
    localStorage.setItem("__controlPanelDetails", JSON.stringify(get().controlPanelDetails));
    localStorage.setItem("__configurationNote", JSON.stringify(get().configurationNote));
    localStorage.setItem("__configurationType", JSON.stringify(get().configurationType));
    localStorage.setItem("__coLocationBackupPrice", JSON.stringify(get().coLocationBackupPrice));
    localStorage.setItem("__selectedYear", JSON.stringify(get().selectedYear));


  },
  checkLocalStorage: () => {
    if (typeof localStorage.getItem("__products") !== "undefined" && localStorage.getItem("__products") !== null && localStorage.getItem("__products") !== "")
      set((state) => ({ configurationTotal: JSON.parse(localStorage.getItem("__products")) }));
    if (typeof localStorage.getItem("__configuration") !== "undefined" && localStorage.getItem("__configuration") !== null && localStorage.getItem("__configuration") !== "")
      set((state) => ({ configuration: JSON.parse(localStorage.getItem("__configuration")) }));
    if (typeof localStorage.getItem("__componentValues") !== "undefined" && localStorage.getItem("__componentValues") !== null && localStorage.getItem("__componentValues") !== "")
      set((state) => ({ componentValues: JSON.parse(localStorage.getItem("__componentValues")) }));
    if (typeof localStorage.getItem("__selectedDisk1") !== "undefined" && localStorage.getItem("__selectedDisk1") !== null && localStorage.getItem("__selectedDisk1") !== "")
      set((state) => ({ selectedDisk1: JSON.parse(localStorage.getItem("__selectedDisk1")) }));
    if (typeof localStorage.getItem("__selectedDisk2") !== "undefined" && localStorage.getItem("__selectedDisk2") !== null && localStorage.getItem("__selectedDisk2") !== "")
      set((state) => ({ selectedDisk2: JSON.parse(localStorage.getItem("__selectedDisk2")) }));
    if (typeof localStorage.getItem("__backupStack") !== "undefined" && localStorage.getItem("__backupStack") !== null && localStorage.getItem("__backupStack") !== "")
      set((state) => ({ backupStack: JSON.parse(localStorage.getItem("__backupStack")) }));
    if (typeof localStorage.getItem("__wafPrice") !== "undefined" && localStorage.getItem("__wafPrice") !== null && localStorage.getItem("__wafPrice") !== "")
      set((state) => ({ wafPrice: JSON.parse(localStorage.getItem("__wafPrice")) }));
    if (typeof localStorage.getItem("__IPSPrice") !== "undefined" && localStorage.getItem("__IPSPrice") !== null && localStorage.getItem("__IPSPrice") !== "")
      set((state) => ({ IPSPrice: JSON.parse(localStorage.getItem("__IPSPrice")) }));
    if (typeof localStorage.getItem("__DDOSPrice") !== "undefined" && localStorage.getItem("__DDOSPrice") !== null && localStorage.getItem("__DDOSPrice") !== "")
      set((state) => ({ DDOSPrice: JSON.parse(localStorage.getItem("__DDOSPrice")) }));
    if (typeof localStorage.getItem("__defaultPaymentCycle") !== "undefined" && localStorage.getItem("__defaultPaymentCycle") !== null && localStorage.getItem("__defaultPaymentCycle") !== "")
      set((state) => ({ defaultPaymentCycle: JSON.parse(localStorage.getItem("__defaultPaymentCycle")) }));
    if (typeof localStorage.getItem("__SelectedDDOSPrice") !== "undefined" && localStorage.getItem("__SelectedDDOSPrice") !== null && localStorage.getItem("__SelectedDDOSPrice") !== "")
      set((state) => ({ SelectedDDOSPrice: JSON.parse(localStorage.getItem("__SelectedDDOSPrice")) }));
    if (typeof localStorage.getItem("__OSPrice") !== "undefined" && localStorage.getItem("__OSPrice") !== null && localStorage.getItem("__OSPrice") !== "")
      set((state) => ({ OSPrice: JSON.parse(localStorage.getItem("__OSPrice")) }));
    if (typeof localStorage.getItem("__DBPrice") !== "undefined" && localStorage.getItem("__DBPrice") !== null && localStorage.getItem("__DBPrice") !== "")
      set((state) => ({ DBPrice: JSON.parse(localStorage.getItem("__DBPrice")) }));
    if (typeof localStorage.getItem("__totalCpuCore") !== "undefined" && localStorage.getItem("__totalCpuCore") !== null && localStorage.getItem("__totalCpuCore") !== "")
      set((state) => ({ totalCpuCore: JSON.parse(localStorage.getItem("__totalCpuCore")) }));
    if (typeof localStorage.getItem("__selectedCpu") !== "undefined" && localStorage.getItem("__selectedCpu") !== null && localStorage.getItem("__selectedCpu") !== "")
      set((state) => ({ selectedCpu: JSON.parse(localStorage.getItem("__selectedCpu")) }));
    if (typeof localStorage.getItem("__selectedFirewall") !== "undefined" && localStorage.getItem("__selectedFirewall") !== null && localStorage.getItem("__selectedFirewall") !== "")
      set((state) => ({ selectedFirewall: JSON.parse(localStorage.getItem("__selectedFirewall")) }));
    if (typeof localStorage.getItem("__selectedOS") !== "undefined" && localStorage.getItem("__selectedOS") !== null && localStorage.getItem("__selectedOS") !== "")
      set((state) => ({ selectedOS: JSON.parse(localStorage.getItem("__selectedOS")) }));
    if (typeof localStorage.getItem("__selectedSubOS") !== "undefined" && localStorage.getItem("__selectedSubOS") !== null && localStorage.getItem("__selectedSubOS") !== "")
      set((state) => ({ selectedSubOS: JSON.parse(localStorage.getItem("__selectedSubOS")) }));
    if (typeof localStorage.getItem("__selectedServer") !== "undefined" && localStorage.getItem("__selectedServer") !== null && localStorage.getItem("__selectedServer") !== "")
      set((state) => ({ selectedServer: JSON.parse(localStorage.getItem("__selectedServer")) }));
    if (typeof localStorage.getItem("__basketCount") !== "undefined" && localStorage.getItem("__basketCount") !== null && localStorage.getItem("__basketCount") !== "")
      set((state) => ({ basketCount: JSON.parse(localStorage.getItem("__basketCount")) }));
    if (typeof localStorage.getItem("__controlPanelDetails") !== "undefined" && localStorage.getItem("__controlPanelDetails") !== null && localStorage.getItem("__controlPanelDetails") !== "")
      set((state) => ({ controlPanelDetails: JSON.parse(localStorage.getItem("__controlPanelDetails")) }));
    if (typeof localStorage.getItem("__configurationNote") !== "undefined" && localStorage.getItem("__configurationNote") !== null && localStorage.getItem("__configurationNote") !== "")
      set((state) => ({ configurationNote: JSON.parse(localStorage.getItem("__configurationNote")) }));
    if (typeof localStorage.getItem("__configurationType") !== "undefined" && localStorage.getItem("__configurationType") !== null && localStorage.getItem("__configurationType") !== "")
      localStorage.getItem("__configurationType") !== "undefined" && set((state) => ({ configurationType: JSON.parse(localStorage.getItem("__configurationType")) }));
    if (typeof localStorage.getItem("__coLocationBackupPrice") !== "undefined" && localStorage.getItem("__coLocationBackupPrice") !== null && localStorage.getItem("__coLocationBackupPrice") !== "")
      set((state) => ({ coLocationBackupPrice: JSON.parse(localStorage.getItem("__coLocationBackupPrice")) }));
    if (typeof localStorage.getItem("__selectedYear") !== "undefined" && localStorage.getItem("__selectedYear") !== null && localStorage.getItem("__selectedYear") !== "")
      localStorage.getItem("__selectedYear") !== "0" && localStorage.getItem("__selectedYear") !== "undefined" && set((state) => ({ selectedYear: JSON.parse(localStorage.getItem("__selectedYear")) }));
  }

})));
