import React from "react";

function SecondaryButton({ name, buttonText, onClick, buttonLeftIcon, buttonRightIcon, type, href, style }) {
  return (
    <a href={href}>
      <button type={type} name={name} onClick={onClick}
              className={`w-full py-3 px-4  whitespace-nowrap flex items-center justify-center rounded-md font-medium bg-makdosSecondaryGray hover:bg-white transition-colors duration-300 tr text-md focus:outline-none ${style && style}`}>
        {buttonLeftIcon &&
          <i className={buttonLeftIcon + " font-normal mr-1"}></i>
        }
        {buttonText}
        {buttonRightIcon &&
          <i className={buttonRightIcon + " font-normal ml-1"}></i>
        }
      </button>
    </a>
  );
}

export default SecondaryButton;
