import React from "react";
import { useWindowSize } from "hooks";
import Language from "components/TopHeader/Language";
import Currency from "components/TopHeader/Currency";
import { useTranslation } from "next-i18next";

function TopHeader() {
  const windowSize = useWindowSize();
  const { t } = useTranslation(["common", "nav"]);

  return (
    <>
      <div className="w-full bg-white">
        <div className="container z-50 flex items-center justify-between py-1 mx-auto bg-white ">
          <div className="flex">
            <Language />
            <div className="ml-2">
              <Currency />

            </div>

          </div>

          <ul className="flex mr-2 space-x-2 lg:mr-0 lg:space-x-5">
            <li className="flex items-center">
              <i className=" text-textGray ri-headphone-line"></i>
              <a className="mt-0.5 ml-1 text-xs text-textGray" href="tel:+908503077744" target="_blank">0850 307 77 44</a>
            </li>
            <li className="flex items-center">
              <i className=" text-textGray ri-mail-line"></i>
              <a className="ml-1 text-xs text-textGray" href="mailto:info@makdos.com" target="_blank">{t("common:contact")}</a>
            </li>


            <li className="items-center hidden lg:flex">
              <i className=" text-textGray ri-edit-line"></i>

              <a className="ml-1 text-xs text-textGray" href="https://makdos.blog/" target="_blank">Blog</a>
            </li>

            {/* <li className="flex items-center">
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.63669 9L0.666687 11.3333V0.999995C0.666687 0.823184 0.736925 0.653615 0.861949 0.52859C0.986973 0.403566 1.15654 0.333328 1.33335 0.333328H11.3334C11.5102 0.333328 11.6797 0.403566 11.8048 0.52859C11.9298 0.653615 12 0.823184 12 0.999995V9H3.63669ZM3.17535 7.66666H10.6667V1.66666H2.00002V8.59L3.17535 7.66666ZM5.33335 10.3333H12.158L13.3334 11.2567V4.33333H14C14.1768 4.33333 14.3464 4.40357 14.4714 4.52859C14.5964 4.65361 14.6667 4.82318 14.6667 5V14L11.6967 11.6667H6.00002C5.82321 11.6667 5.65364 11.5964 5.52862 11.4714C5.40359 11.3464 5.33335 11.1768 5.33335 11V10.3333Z"
                  fill="#888888" />
              </svg>
              <a className="ml-1 text-xs text-textGray" href="#">{t("common:liveSupport")}</a>
            </li> */}

          </ul>
        </div>
        <div className="w-full border border-gray-100">
        </div>
      </div>

    </>
  );
}

export default TopHeader;
