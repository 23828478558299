import React from "react";

function Button({ name, buttonText, buttonLeftIcon, buttonRightIcon, onClick, type, style }) {

  return (
    <div>
      <button type={type} onClick={onClick} name={name}
              className={`w-full py-3 px-4 whitespace-nowrap  flex items-center justify-center rounded-md font-medium bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite transition-colors duration-300 tr text-md focus:outline-none ${style}`}>
        {buttonLeftIcon &&
          <i className={buttonLeftIcon + " font-normal mr-1"}></i>
        }
        {buttonText}
        {buttonRightIcon &&
          <i className={buttonRightIcon + " font-normal ml-1"}></i>
        }
      </button>
    </div>
  );
}

export default Button;
