import React, { useEffect, useRef, useState } from "react";
import SignIn from "components/SignIn/SignIn";
import HeaderCard from "components/Header/HeaderCard";
import Button from "components/FormElements/Button";
import Link from "next/link";
import { useToggleHeader, useWindowSize } from "hooks";
import { useAuthStore } from "store/AuthStore";
import { useConfigurationStore } from "store/ConfigurationStore";
import MyAccount from "components/MyAccount";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import NewPasswordModal from "components/SignIn/NewPasswordModal";
import SuccessModal from "components/GlobalModals/SuccessModal";
import Image from "next/image";

function Header() {
  const { t } = useTranslation(["common", "nav"]);
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggin, logginOpen, setLogginOpen, myAccountOpen, setMyAccountOpen] = useAuthStore(((state) => [state.isLoggin, state.logginOpen, state.setLogginOpen, state.myAccountOpen, state.setMyAccountOpen]));
  const [basketCount] = useConfigurationStore(((state) => [state.basketCount]));
  const [lang, setLang] = useState(router.locale);
  const windowSize = useWindowSize();
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [openNewPasswordModal, setOpenNewPasswordModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [buttonText, setButtonText] = useState();


  const [domainOpen, webHostingOpen, sunucularOpen, ePostaOpen, ekstraOpen, kurumsalOpen, toggle, closeToggles] = useToggleHeader();

  // const [domainOpen, setDomainOpen] = useState(false)
  // const [webHostingOpen, setWebHostingOpen] = useState(false)
  // const [sunucularOpen, setSunucularOpen] = useState(false)
  // const [ePostaOpen, setEPostaOpen] = useState(false)
  // const [ekstraOpen, setEkstraOpen] = useState(false)
  // const [kurumsalOpen, setKurumsalOpen] = useState(false)

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeToggles();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (isLoggin) {
      setButtonText(t("common:myAccount"));
    } else {
      setButtonText(t("common:signIn"));
    }

  }, [isLoggin]);

  return (
    <>
      <div className="sticky z-40 px-4 bg-white shadow-md -top-1" ref={wrapperRef} onMouseLeave={() => closeToggles()}>
        <div className="container mx-auto lg:px-6 md:px-0">
          <div className="flex items-center justify-between py-6 border-gray-100 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link href="/">
                <a>
                  <img className="hidden lg:block" src="/headerLogo.svg" alt="headerLogo" title={"Logo"} width={200} height={73} />
                  <img className="block lg:hidden" src="/headerLogo.svg" alt="headerLogo" title={"Logo"} width={140} height={73} />
                </a>
              </Link>
            </div>
            <div className="flex -my-2 -mr-2 lg:hidden">
              <div className="w-20 h-10 p-2 border-2 border-gray-300 border-opacity-50 rounded-lg">
                <Link href="/basket" as={t("nav:basket")}>
                  <div
                    className="flex justify-center text-base font-medium text-gray-500 align-middle whitespace-nowrap hover:text-gray-900">
                    <svg width="17" height="16" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.00001 0.333374C8.06088 0.333374 9.07829 0.754801 9.82844 1.50495C10.5786 2.25509 11 3.27251 11 4.33337V5.00004H13.6667V6.33337H12.8887L12.384 12.3887C12.3701 12.5553 12.2942 12.7106 12.1712 12.8238C12.0482 12.9371 11.8872 12.9999 11.72 13H2.28001C2.11284 12.9999 1.95181 12.9371 1.82882 12.8238C1.70584 12.7106 1.62989 12.5553 1.61601 12.3887L1.11068 6.33337H0.333344V5.00004H3.00001V4.33337C3.00001 3.27251 3.42144 2.25509 4.17158 1.50495C4.92173 0.754801 5.93914 0.333374 7.00001 0.333374ZM11.5507 6.33337H2.44868L2.89334 11.6667H11.106L11.5507 6.33337ZM7.66668 7.66671V10.3334H6.33334V7.66671H7.66668ZM5.00001 7.66671V10.3334H3.66668V7.66671H5.00001ZM10.3333 7.66671V10.3334H9.00001V7.66671H10.3333ZM7.00001 1.66671C6.31585 1.66671 5.65786 1.92966 5.16214 2.40119C4.66641 2.87272 4.37088 3.51674 4.33668 4.20004L4.33334 4.33337V5.00004H9.66668V4.33337C9.66668 3.64921 9.40372 2.99122 8.93219 2.4955C8.46067 1.99978 7.81665 1.70425 7.13334 1.67004L7.00001 1.66671Z"
                        fill="#242126" />
                    </svg>
                    <div
                      className="flex items-center justify-center w-5 h-5 ml-2 text-xs text-center bg-black rounded-full text-textWhite">{basketCount}</div>
                  </div>
                </Link>
              </div>
              {isLoggin ?
                <button onClick={() => {
                  setMyAccountOpen(!myAccountOpen);
                }}
                  className="flex items-center justify-center w-full px-1 py-1 mx-2 font-medium transition-colors duration-300 rounded-md bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite tr text-md focus:outline-none">
                  <i className="text-xl font-medium ri-user-line"></i>
                </button>
                :
                <button onClick={() => {
                  (setLogginOpen(!logginOpen));
                }}
                  className="flex items-center justify-center w-full px-1 py-1 mx-2 font-medium transition-colors duration-300 rounded-md bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite tr text-md focus:outline-none">
                  <i className="text-xl font-medium ri-user-line"></i>
                </button>
              }

              <button onClick={() => {
                setMenuOpen(!menuOpen);
                setLogginOpen(false);
              }} type="button"
                className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-lg hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <nav className="hidden space-x-5 lg:flex pr-7">
              {/* Domain  */}
              <div className="relative ">
                <Link href="/domains" as={t("nav:domainservices")}>
                  <a >
                    <button onMouseOver={() => toggle("1")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      {/* <div className="absolute bg-transparent discount -top-8">
                        <div className="w-full p-0 px-1 py-0.5 m-0 rounded-2xl bg-red-600 ">
                          <span className="px-1.5 py-0,5 text-[9px] font-semibold text-textWhite">{t('discount')}</span>
                        </div>
                        <div className="absolute w-full -bottom-1.5">
                          <svg className="p-0 mx-auto " width="10" height="8" viewBox="0 0 10 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.77771 7.03783C5.37747 7.533 4.62253 7.533 4.22228 7.03783L0.254217 2.12862C-0.274357 1.47468 0.191081 0.5 1.03193 0.5L8.96807 0.500001C9.80892 0.500001 10.2744 1.47468 9.74578 2.12862L5.77771 7.03783Z"
                              fill="#dc2626" />
                          </svg>
                        </div>
                      </div> */}
                      <span>{t("common:domain")}</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>
                {domainOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-3 bg-white lg:gap-5">
                        {/* <HeaderCard icon='ri-add-circle-line' title='Alan Adı Kayıt'
                          description='Uygun Fiyata .com .net alan adları, anında aktivasyon' /> */}
                        <Link href="/domainsearch" as={t("nav:domainfind")}>
                          <a>
                            <HeaderCard href={t("nav:domainfind")} icon="ri-search-2-line"
                              title={t("common:domainNameSearch")}
                              description={t("common:domainNameSearchDescription")} as={t("nav:domainfind")} />
                          </a>
                        </Link>
                        <Link href="/domaintransfer" as={t("nav:domaintransfer")}>
                          <a>
                            <HeaderCard href={t("nav:domaintransfer")} icon="ri-arrow-left-right-line"
                              title={t("common:domainTransfer")}
                              description={t("common:domainTransferDescription")} />
                          </a>
                        </Link>
                      </div>
                      {/* <div className='grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 '>
                      
                        <Link href='/domainsearch' >
                          <HeaderCard icon='ri-file-list-line' title='Alan Adı Fiyat Listesi'
                            description='Tüm uzantılardaki alan adlarının fiyatlarını öğrenin.' />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                }
              </div>
              {/* Web Hosting */}
              <div className="relative">
                <Link href="/web-hosting" as={t("nav:hostingservices")}>
                  <a >
                    <button onMouseOver={() => toggle("2")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      <span>Web Hosting</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>
                {webHostingOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 pt-1 pb-4 mt-2 bg-white lg:gap-4">
                        <Link href="/web-hosting/cpanel" as={t("nav:personalwebhosting")}>
                          <a>
                            <HeaderCard href={t("nav:personalwebhosting")} icon="ri-user-line"
                              title={t("common:personalWebHosting")}
                              description={t("common:personalWebHostingDescription")} />
                          </a>
                        </Link>
                        {/* <Link href='/corporatewebhosting' as={t('nav:corporatewebhosting')}>
                          <HeaderCard href={t('nav:corporatewebhosting')} icon='ri-building-line' title={t('common:corporateWebHosting')}
                            description={t('common:corporateWebHostingDescription')} />
                        </Link> */}
                        {/* <Link href='/bayiwebhosting' as={t('nav:resellerwebhosting')}> */}
                        <a>
                          <HeaderCard
                            //href={t('nav:resellerwebhosting')}
                            disable icon="ri-contacts-line" title={t("common:resellerWebHosting")}
                            description={t("common:resellerWebHostingDescription")} />
                        </a>
                        {/* </Link> */}
                      </div>

                    </div>
                  </div>
                }
              </div>
              {/* sunucular */}
              <div className="relative">
                <Link href="/servers" as={t("nav:serverservices")}>
                  <a >
                    <button onMouseOver={() => toggle("3")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      <span>{t("common:servers")}</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>
                {sunucularOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 bg-white lg:gap-5">
                        <Link href="/servers/cloud/windows" as={t("nav:windowsvirtualservers")}>
                          <a>
                            <HeaderCard href={t("nav:windowsvirtualservers")} icon="ri-windows-line"
                              title={t("common:windowsCloudServers")}
                              description={t("common:windowsCloudServersDescription")} />
                          </a>
                        </Link>
                        <Link href="/servers/cloud/linux" as={t("nav:linuxvirtualservers")}>
                          <a>
                            <HeaderCard href={t("nav:linuxvirtualservers")} icon="ri-ubuntu-line"
                              title={t("common:linuxCloudServers")}
                              description={t("common:linuxCloudServersDescription")} />
                          </a>
                        </Link>
                      </div>
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 ">
                        <Link href="/servers/dedicated" as={t("nav:dedicatedservers")}>
                          <a>
                            <HeaderCard href={t("nav:dedicatedservers")} icon="ri-server-line"
                              title={t("common:dedicatedServers")}
                              description={t("common:dedicatedServersDescription")} />
                          </a>
                        </Link>
                        <Link href="/servers/co-location" as={t("nav:colocation")}>
                          <a>
                            <HeaderCard href={t("nav:colocation")} icon="ri-building-2-line"
                              title={t("common:co-location")}
                              description={t("common:co-locationDescription")} />
                          </a>
                        </Link>
                      </div>
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 ">
                        <Link href="/servers/cloud-servers" as={t("nav:cloudservers")}>
                          <a>
                            <HeaderCard href={t("nav:cloudservers")} icon="ri-cloud-line" title={t("common:cloudServers")}
                              description={t("common:cloudServersDescription")} />
                          </a>
                        </Link>

                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* E-Posta */}
              <div className="relative">
                <Link href="/email" as={t("nav:emailservices")}>
                  <a>
                    <button onMouseOver={() => toggle("4")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      <span>{t("common:e-mail")}</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>

                {ePostaOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5">
                        <Link href="/email/e-mail" as={t("nav:corporateemail")}>
                          <a>
                            <HeaderCard href={t("nav:corporateemail")} icon="ri-mail-check-line"
                              title={t("common:corporateEmail")}
                              description={t("common:corporateEmailDescription")} />
                          </a>
                        </Link>
                        <HeaderCard disable icon="ri-umail-send-line" title={t("common:emailMarketing")}
                          description={t("common:emailMarketingDescription")} />
                      </div>
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 ">
                        <Link href="/email/iys" as={t("nav:iys")}>
                          <a>
                            <HeaderCard href={t("nav:iys")} icon="ri-user-search-line"
                              title={t("common:messageManagementSystem")}
                              description={t("common:messageManagementSystemDescription")} />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* Ekstra */}
              <div className="relative">
                <Link href="/extra" as={t("nav:ekstraservices")}>
                  <a >
                    <button onMouseOver={() => toggle("5")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      <span>{t("common:extra")}</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>

                {ekstraOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 bg-white lg:gap-5">
                        <Link href="/extra/ssl-certificates" as={t("nav:sslcertificates")}>
                          <a>
                            <HeaderCard href={t("nav:sslcertificates")} icon="ri-lock-2-line"
                              title={t("common:SSLCertificate")}
                              description={t("common:SSLCertificateDescription")} />
                          </a>
                        </Link>

                        <Link href="/extra/control-panels" as={t("nav:controlpanel")}>
                          <a>
                            <HeaderCard href={t("nav:controlpanel")} icon="ri-window-2-line"
                              title={t("common:controlPanelLicance")}
                              description={t("common:controlPanelLicanceDescription")} />
                          </a>
                        </Link>
                      </div>
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 ">
                        <Link href="/extra/firewall-and-security" as={t("nav:firewallandsecurity")}>
                          <a>
                            <HeaderCard href={t("nav:firewallandsecurity")} icon="ri-shield-check-line"
                              title={t("common:firewallAndSecurity")}
                              description={t("common:firewallAndSecurityDescription")} />
                          </a>
                        </Link>

                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* Kurumsal */}
              <div className="relative">
                <Link href="/aboutusservices" as={t("nav:corporate")}>
                  <a >
                    <button onMouseOver={() => toggle("6")} type="button"
                      className="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-lg group hover:text-gray-900 focus:outline-none ">
                      <span>{t("common:corporate")}</span>
                      <svg className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                      </svg>
                    </button>
                  </a>
                </Link>
                {kurumsalOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-3xl px-2 mt-3 -ml-4 transform bg-white border-t-4 rounded-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 border-makdosYellow">
                    <svg className="absolute right-1/2 -top-3" width="9" height="8" viewBox="0 0 9 8" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
                        fill="#FFDF0F" />
                    </svg>
                    <div
                      className="overflow-hidden rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 bg-white lg:gap-5">
                        <Link href="/corporate/about-us" as={t("nav:aboutus")}>
                          <a>
                            <HeaderCard href={t("nav:aboutus")} icon="ri-community-line" title={t("common:aboutUs")}
                              description={t("common:aboutUsDescription")} />
                          </a>
                        </Link>
                        <Link href="/corporate/financial-and-commercial-information" as={t("nav:financialinformation")}>
                          <a>
                            <HeaderCard href={t("nav:financialinformation")} icon="ri-safe-line"
                              title={t("common:financialInformation")}
                              description={t("common:financialInformationDescription")} />
                          </a>
                        </Link>
                      </div>
                      <div className="grid grid-cols-2 px-5 py-1 mt-2 mb-2 bg-white lg:gap-5 ">

                        <Link href="/corporate/infrastructure" as={t("nav:ourinfrastructure")}>
                          <a>
                            <HeaderCard href={t("nav:ourinfrastructure")} icon="ri-building-4-line"
                              title={t("common:infrastructure")}
                              description={t("common:infrastructureDescription")} />
                          </a>
                        </Link>
                        <Link href="/corporate/contact" as={t("nav:contactus")}>
                          <a>
                            <HeaderCard href={t("nav:contactus")} icon="ri-chat-quote-line" title={t("common:contact")}
                              description={t("common:contactDescription")} />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </nav>
            <div className="items-center justify-end hidden lg:flex lg:flex-1 lg:w-0">
              <Link href="/basket" as={t("nav:basket")}>
                <a >
                  <div
                    className="flex items-center justify-center w-20 h-12 p-2 border-2 border-gray-300 border-opacity-50 rounded-lg cursor-pointer">
                    <div
                      className="flex items-center justify-center py-1 font-medium text-gray-500 hover:text-gray-900">
                      <svg width="18" height="17" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.00001 0.333374C8.06088 0.333374 9.07829 0.754801 9.82844 1.50495C10.5786 2.25509 11 3.27251 11 4.33337V5.00004H13.6667V6.33337H12.8887L12.384 12.3887C12.3701 12.5553 12.2942 12.7106 12.1712 12.8238C12.0482 12.9371 11.8872 12.9999 11.72 13H2.28001C2.11284 12.9999 1.95181 12.9371 1.82882 12.8238C1.70584 12.7106 1.62989 12.5553 1.61601 12.3887L1.11068 6.33337H0.333344V5.00004H3.00001V4.33337C3.00001 3.27251 3.42144 2.25509 4.17158 1.50495C4.92173 0.754801 5.93914 0.333374 7.00001 0.333374ZM11.5507 6.33337H2.44868L2.89334 11.6667H11.106L11.5507 6.33337ZM7.66668 7.66671V10.3334H6.33334V7.66671H7.66668ZM5.00001 7.66671V10.3334H3.66668V7.66671H5.00001ZM10.3333 7.66671V10.3334H9.00001V7.66671H10.3333ZM7.00001 1.66671C6.31585 1.66671 5.65786 1.92966 5.16214 2.40119C4.66641 2.87272 4.37088 3.51674 4.33668 4.20004L4.33334 4.33337V5.00004H9.66668V4.33337C9.66668 3.64921 9.40372 2.99122 8.93219 2.4955C8.46067 1.99978 7.81665 1.70425 7.13334 1.67004L7.00001 1.66671Z"
                          fill="#242126" />
                      </svg>
                      <div
                        className="flex items-center justify-center w-5 h-5 ml-2 text-xs font-medium text-center bg-black rounded-full text-textWhite">
                        <p>{basketCount}</p></div>
                    </div>
                  </div>
                </a>

              </Link>
              <div className="relative">
                <div className="ml-2">
                  <Button onClick={() => {
                    isLoggin ? setMyAccountOpen(!myAccountOpen) : setLogginOpen(!logginOpen);
                  }}
                    buttonText={buttonText}
                    buttonLeftIcon="ri-user-line" />
                </div>


                {logginOpen && !isLoggin &&
                  <div
                    className="absolute z-10 w-screen max-w-xs px-2 mt-3 duration-500 transform -translate-x-1/2 -left-20 sm:px-0 animate-[pulse_1s_ease-in-out_1]">
                    <SignIn setOpenNewPasswordModal={(v) => setOpenNewPasswordModal(v)} />
                  </div>
                }

                {isLoggin && myAccountOpen &&
                  <div
                    className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 -left-20 sm:px-0">
                    <MyAccount />
                  </div>
                }
              </div>

              {/* <a href="#" className="inline-flex items-center justify-center px-4 py-2 ml-4 text-base font-medium text-black transition-colors duration-300 border border-transparent rounded-lg shadow-sm group whitespace-nowrap bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite">
              <i className="mr-1 ri-user-line"></i>
                <span className="ml-2">Müşteri Paneli</span>
              </a> */}
            </div>
          </div>
          {windowSize?.width < 800 &&
            isLoggin && myAccountOpen && !menuOpen &&
            <div className="mt-5">
              <MyAccount />
            </div>

          }
          {windowSize?.width < 800 &&

            !isLoggin && logginOpen && !menuOpen &&
            <div className="mt-5 ">
              <SignIn setOpenNewPasswordModal={(v) => setOpenNewPasswordModal(v)} />
            </div>
          }


        </div>
        {menuOpen &&
          <div className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden ">
            <div
              className="bg-white divide-y-2 rounded-lg rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
              <div className="h-screen px-5 pt-5 pb-6 overflow-y-auto">
                <div className="flex items-center justify-between">
                  <Link href="/">
                    <a>
                      <img className="w-auto h-8 sm:h-10" src="/headerLogo.png" alt="headerLogo" title="Logo" width={160}
                        height={43} />
                    </a>
                  </Link>
                  <div className="flex -mr-2">
                    <div className="w-20 h-10 p-2 border-2 border-gray-300 border-opacity-50 rounded-lg">
                      <Link href="/basket" as={t("nav:basket")}>
                        <div
                          onClick={() => setMenuOpen(!menuOpen)}
                          className="flex justify-center text-base font-medium text-gray-500 align-middle hover:text-gray-900">
                          <svg width="17" height="16" viewBox="0 0 14 13" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.00001 0.333374C8.06088 0.333374 9.07829 0.754801 9.82844 1.50495C10.5786 2.25509 11 3.27251 11 4.33337V5.00004H13.6667V6.33337H12.8887L12.384 12.3887C12.3701 12.5553 12.2942 12.7106 12.1712 12.8238C12.0482 12.9371 11.8872 12.9999 11.72 13H2.28001C2.11284 12.9999 1.95181 12.9371 1.82882 12.8238C1.70584 12.7106 1.62989 12.5553 1.61601 12.3887L1.11068 6.33337H0.333344V5.00004H3.00001V4.33337C3.00001 3.27251 3.42144 2.25509 4.17158 1.50495C4.92173 0.754801 5.93914 0.333374 7.00001 0.333374ZM11.5507 6.33337H2.44868L2.89334 11.6667H11.106L11.5507 6.33337ZM7.66668 7.66671V10.3334H6.33334V7.66671H7.66668ZM5.00001 7.66671V10.3334H3.66668V7.66671H5.00001ZM10.3333 7.66671V10.3334H9.00001V7.66671H10.3333ZM7.00001 1.66671C6.31585 1.66671 5.65786 1.92966 5.16214 2.40119C4.66641 2.87272 4.37088 3.51674 4.33668 4.20004L4.33334 4.33337V5.00004H9.66668V4.33337C9.66668 3.64921 9.40372 2.99122 8.93219 2.4955C8.46067 1.99978 7.81665 1.70425 7.13334 1.67004L7.00001 1.66671Z"
                              fill="#242126" />
                          </svg>
                          <div
                            className="flex items-center justify-center w-5 h-5 ml-2 text-xs text-center bg-black rounded-full text-textWhite">{basketCount}</div>
                        </div>
                      </Link>
                    </div>

                    {/* <button onClick={() => { isLoggin ? (setMyAccountOpen(!myAccountOpen)) : (setLogginOpen(!logginOpen)) }}
                      className='flex items-center justify-center w-full px-1 py-1 mx-2 font-medium transition-colors duration-300 rounded-md bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite tr text-md focus:outline-none'>
                      <i className='text-xl font-medium ri-user-line'></i>
                    </button> */}
                    <button onClick={() => setMenuOpen(!menuOpen)} type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-lg hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                </div>
                {!logginOpen &&
                  <div>
                    <div className="mt-6">
                      <div onClick={() => toggle("1", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">{t("common:domain")}</p>
                        {!domainOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {domainOpen &&
                        <div className="flex flex-col mt-5">
                          {/* <HeaderCard icon='ri-add-circle-line' title='Alan Adı Kayıt'
                            description='Uygun Fiyata .com .net alan adları, anında aktivasyon' /> */}
                          <Link href="/domainsearch" as={t("nav:domainfind")}>
                            <a>
                              <HeaderCard icon="ri-search-2-line" title={t("common:domainNameSearch")}
                                description={t("common:domainNameSearchDescription")} />
                            </a>
                          </Link>
                          <Link href="/domaintransfer" as={t("nav:domaintransfer")}>
                            <a>
                              <HeaderCard icon="ri-arrow-left-right-line" title={t("common:domainTransfer")}
                                description={t("common:domainTransferDescription")} />
                            </a>
                          </Link>,
                          {/* <Link href='/domainsearch' >
                            <HeaderCard icon='ri-file-list-line' title='Alan Adı Fiyat Listesi'
                              description='Tüm uzantılardaki alan adlarının fiyatlarını öğrenin.' />
                          </Link> */}
                        </div>
                      }
                    </div>
                    <div className="mt-6">
                      <div onClick={() => toggle("2", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">Web Hosting</p>
                        {!webHostingOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {webHostingOpen &&
                        <div className="flex flex-col mt-5">
                          <Link href="/web-hosting/cpanel" as={t("nav:personalwebhosting")}>
                            <a>
                              <HeaderCard icon="ri-user-line" title={t("common:personalWebHosting")}
                                description={t("common:personalWebHostingDescription")} />
                            </a>
                          </Link>
                          {/* <Link href='/corporatewebhosting' as={t('nav:corporatewebhosting')}>
                            <HeaderCard icon='ri-building-line' title={t('common:corporateWebHosting')}
                              description={t('common:corporateWebHostingDescription')} />
                          </Link> */}
                          {/* <Link href='/bayiwebhosting' as={t('nav:resellerwebhosting')}> */}
                          <HeaderCard disable icon="ri-contacts-line" title={t("common:resellerWebHosting")}
                            description={t("common:resellerWebHostingDescription")} />
                          {/* </Link> */}

                        </div>
                      }
                    </div>
                    <div className="mt-6">
                      <div onClick={() => toggle("3", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">{t("common:servers")}</p>
                        {!sunucularOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {sunucularOpen &&
                        <div className="flex flex-col mt-5">
                          <Link href="/servers/cloud/windows" as={t("nav:windowsvirtualservers")}>
                            <a>
                              <HeaderCard icon="ri-windows-line" title={t("common:windowsCloudServers")}
                                description={t("common:windowsCloudServersDescription")} />
                            </a>
                          </Link>
                          <Link href="/servers/cloud/linux" as={t("nav:linuxvirtualservers")}>
                            <a>
                              <HeaderCard icon="ri-ubuntu-line" title={t("common:linuxCloudServers")}
                                description={t("common:linuxCloudServersDescription")} />
                            </a>
                          </Link>
                          <Link href="/servers/dedicated" as={t("nav:dedicatedservers")}>
                            <a>
                              <HeaderCard icon="ri-server-line" title={t("common:dedicatedServers")}
                                description={t("common:dedicatedServersDescription")} />
                            </a>
                          </Link>
                          <Link href="/servers/co-location">
                            <a>
                              <HeaderCard icon="ri-building-2-line" title={t("common:co-location")}
                                description={t("common:co-locationDescription")} />
                            </a>
                          </Link>

                          <Link href="/servers/cloud-servers" as={t("nav:cloudservers")}>
                            <a>
                              <HeaderCard href={t("nav:cloudservers")} icon="ri-cloud-line" title={t("common:cloudServers")}
                                description={t("common:cloudServersDescription")} />
                            </a>
                          </Link>
                        </div>
                      }
                    </div>
                    <div className="mt-6">
                      <div onClick={() => toggle("4", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">{t("common:e-mail")}</p>
                        {!ePostaOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {ePostaOpen &&
                        <div className="flex flex-col mt-5">
                          <Link href="/email/e-mail" as={t("nav:corporateemail")}>
                            <a>
                              <HeaderCard icon="ri-mail-check-line" title={t("common:corporateEmail")}
                                description={t("common:corporateEmailDescription")} />
                            </a>
                          </Link>
                          <HeaderCard disable icon="ri-umail-send-line" title={t("common:emailMarketing")}
                            description={t("common:emailMarketingDescription")} />
                          <Link href="/email/iys" as={t("nav:iys")}>
                            <a>
                              <HeaderCard icon="ri-user-search-line" title={t("common:messageManagementSystem")}
                                description={t("common:messageManagementSystemDescription")} />
                            </a>
                          </Link>
                        </div>
                      }
                    </div>
                    <div className="mt-6">
                      <div onClick={() => toggle("5", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">{t("common:extra")}</p>
                        {!ekstraOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {ekstraOpen &&
                        <div className="flex flex-col mt-5">
                          <Link href="/extra/ssl-certificates" as={t("nav:sslcertificates")}>
                            <a>
                              <HeaderCard icon="ri-lock-2-line" title={t("common:SSLCertificate")}
                                description={t("common:SSLCertificateDescription")} />
                            </a>
                          </Link>
                          <Link href="/extra/control-panels" as={t("nav:controlpanel")}>
                            <a>
                              <HeaderCard icon="ri-window-2-line" title={t("common:controlPanelLicance")}
                                description={t("common:ControlPanelLicanceDescription")} />
                            </a>
                          </Link>
                          <Link href="/extra/firewall-and-security" as={t("nav:firewallandsecurity")}>
                            <a>
                              <HeaderCard icon="ri-shield-check-line" title={t("common:firewallAndSecurity")}
                                description={t("common:firewallAndSecurityDescription")} />
                            </a>
                          </Link>

                        </div>
                      }
                    </div>
                    <div className="mt-6">
                      <div onClick={() => toggle("6", true)} className="flex items-center justify-between p-2">
                        <p className="font-medium">{t("common:corporate")}</p>
                        {!kurumsalOpen ?
                          <i className="text-2xl ri-arrow-right-s-line"></i>
                          : <i className="text-2xl ri-arrow-up-s-line"></i>

                        }
                      </div>
                      {kurumsalOpen &&
                        <div className="flex flex-col mt-5">
                          <Link href="/corporate/about-us" as={t("nav:aboutus")}>
                            <a>
                              <HeaderCard icon="ri-community-line" title={t("common:aboutUs")}
                                description={t("common:aboutUsDescription")} />
                            </a>
                          </Link>
                          <Link href="/corporate/financial-and-commercial-information" as={t("nav:financialinformation")}>
                            <a>
                              <HeaderCard icon="ri-safe-line" title={t("common:financialInformation")}
                                description={t("common:financialInformationDescription")} />
                            </a>
                          </Link>
                          <Link href="/corporate/infrastructure" as={t("nav:ourinfrastructure")}>
                            <a>
                              <HeaderCard icon="ri-building-4-line" title={t("common:infrastructure")}
                                description={t("common:infrastructureDescription")} />
                            </a>
                          </Link>
                          <Link href="/corporate/contact" as={t("nav:contactus")}>
                            <a>
                              <HeaderCard icon="ri-chat-quote-line" title={t("common:contact")}
                                description={t("common:infrastructureDescription")} />
                            </a>
                          </Link>
                        </div>
                      }
                    </div>


                  </div>
                }

              </div>
            </div>
          </div>}
      </div>
      {/* {openActivationModal &&
        <PasswordResetActivationModal setOpenModal={() => setOpenActivationModal(false)} onClick={() => { setOpenNewPasswordModal(true); setOpenActivationModal(false) }} />

      } */}

      {openNewPasswordModal &&
        <NewPasswordModal mail={openNewPasswordModal} setOpenModal={() => setOpenActivationModal(false)}
          onClick={() => {
            setOpenSuccessModal(true);
            setOpenNewPasswordModal(false);
          }}
          closeModal={() => {
            setOpenNewPasswordModal(false);
          }}
        />

      }
      {openSuccessModal &&
        <SuccessModal title="Parola Değiştirildi" message="Yeni parolanız ile birlikte hesabınıza giriş yapabilirsiniz."
          onClick={() => setOpenSuccessModal(false)} setOpenModal={() => setOpenSuccessModal(false)}
          buttonText="Tamam" />

      }
    </>
  );
}

export default Header;