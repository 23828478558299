import React, { useState } from "react";

function IconTextBox({
                       labelName,
                       inputName,
                       type,
                       placeholder,
                       iconChange,
                       textBoxIcon,
                       textBoxSecondIcon,
                       innerRef,
                       value,
                       onChange
                     }) {
  const [changeIcon, setChangeIcon] = useState(false);

  return (
    <div className="flex flex-col mt-5 lg:mt-0">
      <label className="mb-1 ">{labelName}</label>
      <div className="flex w-full  items-center relative">
        <input ref={innerRef} value={value && value} onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
               className="w-full border-2  border-makdosSecondaryGray rounded-md  py-2 px-3 focus:outline-none focus:border-transparent focus:border-2 focus:border-r-0 focus:border-makdosYellow "
               type={(iconChange && !changeIcon) ? type : "text"} name={inputName} placeholder={placeholder} />
        <div onClick={() => setChangeIcon(!changeIcon)}
             className="w-12 flex justify-center  py-2 rounded-md rounded-l-none absolute right-0 cursor-pointer">
          <div className="mr-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
          <i
            className={(iconChange ? (changeIcon ? textBoxIcon : textBoxSecondIcon) : textBoxIcon) + " text-textGray"}></i>
        </div>

      </div>

    </div>
  );
}

export default IconTextBox;
