Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _resendOTP = require("../hooks/resendOTP");

var _resendOTP2 = _interopRequireDefault(_resendOTP);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function ResendOTP(_ref) {
  var renderTime = _ref.renderTime,
    renderText = _ref.renderText,
    renderButton = _ref.renderButton,
    codeAgain = _ref.codeAgain,
    codeError = _ref.codeError,
    codeSec = _ref.codeSec,
    style = _ref.style,
    className = _ref.className,
    props = _objectWithoutProperties(_ref, [
      "renderTime",
      "renderTimeIcon",
      "renderText",
      "renderButton",
      "style",
      "className"
    ]);

  var _useResendOTP = (0, _resendOTP2.default)(props),
    remainingTime = _useResendOTP.remainingTime,
    handelResendClick = _useResendOTP.handelResendClick;

  return _react2.default.createElement(
    "div",
    {
      className: className || "",
      "data-testid": "otp-resend-root",
      class: codeSec === "seconds" ? "root-otp" : "otp-root",
      style: _extends(
        {
          textAlign: "center",
          justifyContent: "space-between",
          fontSize: 14,
          marginTop: 5
        },
        style
      )
    },
    renderText
      ? renderText()
      : _react2.default.createElement(
        "span",
        { className: "resend-text" },
        codeError
      ),
    renderButton
      ? renderButton({
        disabled: remainingTime !== 0,
        onClick: handelResendClick,
        remainingTime: remainingTime
      })
      : _react2.default.createElement(
        "button",
        { disabled: remainingTime !== 0, onClick: handelResendClick },
        codeAgain
      ),
    _react2.default.createElement(
      "div",
      {
        style: _extends(
          {
            width: "82px",
            margin: "0 auto",
            textAlign: "center",
            color: "#999999"
          },
          style
        )
      },
      _react2.default.createElement("div", {
        className: "ri-timer-line float-left"
      }),
      renderTime
        ? renderTime(remainingTime)
        : _react2.default.createElement(
          "div",
          { className: "text-xs ml-1 resend-text-position float-left" },
          remainingTime,
          " " + codeSec
        )
    )
  );
}

ResendOTP.defaultProps = {
  maxTime: 60,
  timeInterval: 1000,
  style: { fontSize: 14, color: "#555555" }
};

ResendOTP.propTypes = {
  onTimerComplete: _propTypes2.default.func,
  onResendClick: _propTypes2.default.func,
  renderTime: _propTypes2.default.func,
  renderText: _propTypes2.default.func,
  renderButton: _propTypes2.default.func,
  maxTime: _propTypes2.default.number,
  timeInterval: _propTypes2.default.number,
  style: _propTypes2.default.object,
  className: _propTypes2.default.string
};

exports.default = ResendOTP;
