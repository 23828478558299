import React, { useEffect, useRef, useState } from "react";
import { getCustomerCredit, getRedirectToken, logout } from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import { useConfigurationStore } from "store/ConfigurationStore";
import { useWindowSize } from "hooks";
import { useGlobalStore } from "store/GlobalStore";
import { changeCurrency } from "helpers/helpersFunc";
import { useTranslation } from "next-i18next";


function MyAccount() {
  const [isLoggin, setIsLoggin, setLogginOpen, setUserInformations, userInformations, setMyAccountOpen] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.setLogginOpen,
    state.setUserInformations,
    state.userInformations,
    state.setMyAccountOpen
  ]);
  const { t } = useTranslation(["common", "nav"]);

  const [setBasketCount] = useConfigurationStore(((state) => [state.setBasketCount]));
  const windowSize = useWindowSize();
  const [currencyType, currencyRates, isResponsible] = useGlobalStore((state) => [state.currencyType, state.currencyRates, state.isResponsible]);

  const [credit, setCredit] = useState(0);

  const onLogout = () => {
    let guestID = localStorage.getItem("g_id");
    let userID = localStorage.getItem("u_id");

    logout().then(data => {
      if (data.status === "logout") {
        setIsLoggin(false);
        setUserInformations([]);
        localStorage.clear();
        localStorage.setItem("g_id", guestID);
        localStorage.setItem("u_id", userID);
        setBasketCount(0);
        setMyAccountOpen(false);
        setLogginOpen(false);
      }
    }).catch(err => {
      setIsLoggin(false);
      setUserInformations([]);
      localStorage.clear();
      localStorage.setItem("g_id", guestID);
      localStorage.setItem("u_id", userID);
      setBasketCount(0);
      setMyAccountOpen(false);
      setLogginOpen(false);
    });
  };

  const goToCustomerPanel = () => {
    getRedirectToken().then(res => {
      window.open(
        `${process.env.NEXT_PUBLIC_CUSTOMER_PANEL_URL}weblogin?token=${res.access_token}`,
        "_blank"
      );

      // window.open(
      //     `http://0.0.0.0:3001/weblogin?token=${res.access_token}`,
      //     '_blank'
      // );
    });

  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMyAccountOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  useEffect(() => {
    getCustomerCredit().then(data => {
      setCredit(data.credit);
    });
  }, []);

  return (
    <div ref={windowSize.width > 800 ? wrapperRef : null}
      className="relative flex flex-col justify-center px-4 py-6 bg-white rounded-md w-96 lg:px-6 lg:shadow-md lg:border-t-4 lg:border-makdosYellow">
      <svg
        className="absolute hidden right-16 -top-3 lg:block "
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.6273 0.5C4.01517 -0.166667 4.98483 -0.166667 5.3727 0.5L8.86349 6.5C9.25136 7.16667 8.76652 8 7.99079 8H1.00921C0.233477 8 -0.251356 7.16667 0.13651 6.5L3.6273 0.5Z"
          fill="#FFDF0F"
        />
      </svg>
      <div className="w-full mx-auto">
        {userInformations &&
          <>
            <h2
              className="text-2xl font-medium text-selectboxText">{t("common:welcome")}, {userInformations?.customer_info?.customer_name} 👋 <span className="text-xs">{isResponsible ? ("(" + t('authorizedLogin') + ")") : ""}</span> </h2>
            <p className="mt-3 text-cardText">{userInformations?.customer_info?.customer_email}</p>
          </>
        }

        <div className="py-2 my-3 border-t border-b">
          <p className="text-cardText">{t("common:credit")} : <span
            className={`${credit == 0 ? "text-discountRed" : (credit < 10 ? " text-orange-400" : "text-alertGreen")}`}>{changeCurrency(currencyRates, currencyType, credit)} {currencyType}</span>
          </p>
        </div>

        <div className="">

          <button onClick={() => goToCustomerPanel()} title="Müşteri Paneli"
            className="flex items-center w-full px-4 py-3 font-medium transition-colors duration-300 rounded-md bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite tr text-md focus:outline-none">
            <i className="mr-1 text-xl font-medium ri-dashboard-line"></i>
            {t("common:customerPanel")}
          </button>

        </div>
        <div className="mt-5">
          <button onClick={() => onLogout()}
            className="flex items-center w-full px-4 py-3 font-medium transition-colors duration-300 rounded-md group text-textGray hover:text-discountRed text-md focus:outline-none">
            <i
              className="mr-1 text-xl font-medium transition-colors duration-300 ri-logout-box-line text-textGray group-hover:text-discountRed"></i>
            {t("common:signOut")}
          </button>
        </div>

      </div>
    </div>
  );
}

export default MyAccount;
