import create from "zustand";
import { devtools } from "zustand/middleware";
import * as ls from "local-storage";

export const useAuthStore = create(
  devtools((set, get) => ({
    isLoggin: ls.get("__isLoggin") ? ls.get("__isLoggin") : false,
    setIsLoggin: (value) => {
      set((state) => ({
        isLoggin: value
      }));

      get().addToLocalStorageAuth();
    },
    logginOpen: false,
    setLogginOpen: (value) => {
      set((state) => ({
        logginOpen: value
      }));
    },
    myAccountOpen: false,
    setMyAccountOpen: (value) => {
      set((state) => ({
        myAccountOpen: value
      }));
    },

    userInformations: [],
    setUserInformations: (value) => {
      set((state) => ({
        userInformations: value
      }));

      get().addToLocalStorageAuth();
    },

    beforeLogginStatus: false,
    setBeforeLogginStatus: (value) => {
      set((state) => ({
        beforeLogginStatus: value
      }));
    },

    beforeLogginInfo: {},
    setBeforeLogginInfo: (value) => {
      set((state) => ({
        beforeLogginInfo: value
      }));
    },

    addToLocalStorageAuth: () => {
      // localStorage.removeItem("__products")
      localStorage.setItem("__user_at", JSON.stringify(get().userInformations["access_token"]));
      localStorage.setItem("__isLoggin", JSON.stringify(get().isLoggin));
      localStorage.setItem("__userInformations", JSON.stringify(get().userInformations));

    },
    checkLocalStorageAuth: () => {
      if (
        typeof localStorage.getItem("__isLoggin") !== "undefined" &&
        localStorage.getItem("__isLoggin") !== null &&
        localStorage.getItem("__isLoggin") !== ""
      )
        set((state) => ({ isLoggin: JSON.parse(localStorage.getItem("__isLoggin")) }));
      if (
        typeof localStorage.getItem("__userInformations") !== "undefined" &&
        localStorage.getItem("__userInformations") !== null &&
        localStorage.getItem("__userInformations") !== ""
      )
        set((state) => ({
          userInformations: JSON.parse(localStorage.getItem("__userInformations"))
        }));


    }


  }))
);
