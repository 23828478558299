import { useCloseSelectBox } from "hooks";
import React, { useState } from "react";
import { useGlobalStore } from "store/GlobalStore";

function Currency() {
  const [openCurrency, setOpenCurrency] = useState(false);
  const [wrapperRef] = useCloseSelectBox(setOpenCurrency);
  const [currencyType, setCurrencyType] = useGlobalStore((state) => [state.currencyType, state.setCurrencyType]);

  const onSetCurrencyType = (value) => {
    setOpenCurrency(!openCurrency);
    setCurrencyType(value);
  };
  return (
    <div className="relative inline-block text-left" ref={wrapperRef}>
      <div>
        <button type="button" onClick={() => setOpenCurrency(!openCurrency)}
                className="z-50 inline-flex items-center justify-center w-full h-10 px-3 py-1 text-sm font-medium text-gray-700 bg-white border-2 rounded-lg border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none "
                id="options-menu-currency" aria-haspopup="true" aria-expanded="true">

          {currencyType === "₺" ? " ₺ TRY" : (currencyType === "$" ? "$ USD" : "€ EUR")}
          <svg className="w-5 h-5 ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
               aria-hidden="true">
            <path fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {openCurrency &&
        <div className="absolute left-0 z-50 w-32 mt-2 origin-top-left bg-white rounded-lg shadow-lg ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" name="₺" onClick={(e) => onSetCurrencyType(e.target.name)}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border-b-2 border-makdosSecondaryGray hover:bg-gray-50 focus:outline-none"
                    id="options-menu1" aria-haspopup="true" aria-expanded="true">

              ₺ TRY
            </button>
            <button type="button" name="$" onClick={(e) => onSetCurrencyType(e.target.name)}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                    id="options-menu2" aria-haspopup="true" aria-expanded="true">

              $ USD
            </button>
            <button type="button" name="€" onClick={(e) => onSetCurrencyType(e.target.name)}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                    id="options-menu3" aria-haspopup="true" aria-expanded="true">

              € EUR
            </button>
          </div>
        </div>
      }

    </div>
  );
}

export default Currency;
